import Swup from 'swup';
import SwupProgressPlugin from '@swup/progress-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import { initcookies, resetCookieConsent } from "./components/cookies";
import { initMobileNav, resetNav } from "./components/mobilenavigation";
import { setupUniform } from "./components/uniform";
import { initLenis } from "./components/lenisjs";
import { setupAnimations } from "./components/animations";

const swup = new Swup({
    containers: ['.main_area', 'footer', '.nav_area', '#cc-container'],
    plugins: [new SwupBodyClassPlugin(), new SwupProgressPlugin(), new SwupPreloadPlugin(), new SwupScriptsPlugin()],
});

swup.hooks.on('animation:in:end', (visit) => {
    runScripts();
});

swup.hooks.on('animation:out:start', (visit) => {
    resetNav();
    resetCookieConsent();
});
let lenis;
// init
function runScripts() {

    unhover();

    setupAnimations();

    // init smooth scrolling
    lenis = initLenis();

    // added as kirby plugin
    initBackToTop('#btt');

    // init cookie plugin
    initcookies(document.getElementById('btn_cookies'));

    // init mobile navigation
    initMobileNav(lenis); 

    // init forms
    setupUniform();
}

// for touch devices, so last link is not in hover anymore after swup
function unhover() {
    let nav = document.querySelector('nav ul');
    nav.style.pointerEvents = "none";
    setTimeout(() => { nav.style.pointerEvents = "auto"; }, 100);
}

window.onload = (event) => {
    runScripts();
};



